import { FilterTypes } from '@/components/search/filterDropdown/interfaces';
import { AUTHORIZED_FILTER_FIELDS } from '@/constants/constants';
import { ListingStatus, typedKeys } from '@proprioo/hokkaido';

import {
  AlgoliaSearchState,
  OtherCriteriasProps,
  RangeFilterValue
} from '../components/search/search/interfaces';
import { UrlProps } from '../constants/global';
import { TOKENS } from './filters.enum';

export const getRangeState = (
  state: AlgoliaSearchState,
  type: FilterTypes
): RangeFilterValue =>
  (state[type] as RangeFilterValue) || {
    min: 0,
    max: 0
  };

export const generateLinkHref = (link: UrlProps, query: string) => ({
  href: `${link.href}${query}`
});

export const removeDoubleQuotes = (value: string): string =>
  value.replace(/"/g, '').replace(/\s{2,}/g, ' ');

export const buildFinalSalePrice = (isSold: boolean, range: RangeFilterValue) =>
  isSold
    ? ` ${TOKENS.OR} ${buildRangeChoices('finalSalePrice', range, isSold)}`
    : '';

export const buildRangeChoices = (
  key: string,
  { min, max }: RangeFilterValue,
  isSold: boolean
): string => {
  const addFinalSalePrice = key === FilterTypes.PRICE && isSold;

  if (min && max) {
    return `${key} ${TOKENS.GREATER_EQUAL} ${min} ${TOKENS.AND} ${key} ${
      TOKENS.LOWER_EQUAL
    } ${max}${
      addFinalSalePrice ? buildFinalSalePrice(isSold, { min, max }) : ''
    }`;
  }
  if (min && !max) {
    return `${key} ${TOKENS.GREATER_EQUAL} ${min}${
      addFinalSalePrice ? buildFinalSalePrice(isSold, { min, max }) : ''
    }`;
  }
  if (max && !min) {
    return `${key} ${TOKENS.LOWER_EQUAL} ${max}${
      addFinalSalePrice ? buildFinalSalePrice(isSold, { min, max }) : ''
    }`;
  }
  return '';
};

export const buildOtherChoices = (
  criterias: OtherCriteriasProps
): string | undefined => {
  const values = typedKeys<OtherCriteriasProps>(criterias);

  const facets = `${values
    .map(criteria => {
      if (JSON.parse(`${criterias[criteria]}`)) {
        return `${criteria}:"${criterias[criteria]}"`;
      }
    })
    .filter(Boolean)
    .join(` ${TOKENS.AND} `)}`;

  if (facets) {
    return `(${facets})`;
  }
};

export const buildMultipleChoices = (key: string, values: string[]): string =>
  values.length > 0
    ? `${values
        .map((value: string) => {
          if (value.search(/"/g) === -1) {
            return `${key}:"${value}"`;
          }
          return `${key}:${value}`;
        })
        .join(` ${TOKENS.OR} `)}`
    : '';

export const buildFiltersFromQuery = (state: AlgoliaSearchState): string =>
  typedKeys(state)
    .filter(field => AUTHORIZED_FILTER_FIELDS.includes(field as FilterTypes))
    .map(value => {
      const filter = state[value];

      if (value === FilterTypes.OTHER) {
        return buildOtherChoices(filter as OtherCriteriasProps);
      }

      if (Array.isArray(filter) || typeof filter === 'string') {
        return `${buildMultipleChoices(
          value,
          Array.isArray(filter) ? filter : [filter]
        )}`;
      }

      return `${buildRangeChoices(
        value,
        filter as RangeFilterValue,
        state.status === ListingStatus.SOLD
      )}`;
    })
    .filter(Boolean)
    .join(` ${TOKENS.AND} `);
