import qs from 'qs';

import { FilterTypes } from '@/components/search/filterDropdown/interfaces';
import { typedKeys } from '@proprioo/hokkaido';

import {
  AlgoliaSearchState,
  RangeFilterValue
} from '../components/search/search/interfaces';
import {
  AUTHORIZED_FILTER_FIELDS,
  OPERATOR_REGEX
} from '../constants/constants';
import { removeDoubleQuotes } from './filters';
import { TOKENS } from './filters.enum';
import { ArbitraryObject } from './test-utils';

export const OPERATOR_TOKENS: TOKENS[] = [
  TOKENS.GREATER_EQUAL,
  TOKENS.LOWER_EQUAL
];

export type Parser = {
  tokens: string[];
  facets: string[];
};

type QueryObject = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

export const parseTokenAndFacets = (query: string): Parser =>
  query
    ? query.split(new RegExp(OPERATOR_REGEX)).reduce(
        (acc: Parser, value) => {
          if (value !== TOKENS.AND && value !== TOKENS.OR) {
            if (OPERATOR_TOKENS.includes(value as TOKENS)) {
              acc.tokens.push(value.trim());
            } else {
              acc.facets.push(value.trim());
            }
          }
          return acc;
        },
        { tokens: [], facets: [] }
      )
    : { tokens: [], facets: [] };

export const isOther = (filter: string) =>
  [
    'groundFloor',
    'hasCellarOrAnnex',
    'hasLift',
    'hasParking',
    'hasPool',
    'hasOutdoorSpace',
    'withoutRenovationWork'
  ].includes(filter);

export const searchStateToURLObject = (query: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const searchState: any = {};
  const { tokens, facets }: Parser = parseTokenAndFacets(query);

  while (facets.length) {
    const pair = (facets.shift() || '').split(':');
    const [left, right] = pair;

    if (pair.length > 1) {
      const leftCleaned = left.replace(/[()]/g, '');
      const rightCleaned = right.replace(/[()]/g, '');

      const value = removeDoubleQuotes(rightCleaned);

      if (leftCleaned === 'status') {
        searchState[left] = value;
      } else if (isOther(leftCleaned)) {
        searchState['other'] = {
          ...searchState['other'],
          ...(Boolean(JSON.parse(rightCleaned)) && {
            [leftCleaned]: JSON.parse(rightCleaned)
          })
        };
      } else {
        searchState[leftCleaned] = searchState[leftCleaned]
          ? [...searchState[leftCleaned], value]
          : (searchState[leftCleaned] = [value]);
      }
    } else {
      const operator = tokens.shift() || '';
      const nextQueryValue = Math.floor(Number(facets.shift()));

      if (!searchState[left]) {
        searchState[left] = {};
      }

      if (operator === TOKENS.GREATER || operator === TOKENS.GREATER_EQUAL) {
        (searchState[left] as RangeFilterValue).min = nextQueryValue;
      } else if (operator === TOKENS.LOWER || operator === TOKENS.LOWER_EQUAL) {
        (searchState[left] as RangeFilterValue).max = nextQueryValue;
      }
    }
  }

  return searchState;
};

export const readQueryString = (path: string) =>
  qs.parse(path.substring(path.indexOf('?') + 1), {
    parseArrays: true,
    comma: true,
    strictNullHandling: true
  });

export const generateObjectToQuery = (query: Record<string, unknown>): string =>
  qs.stringify(query, {
    arrayFormat: 'comma',
    encode: false
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const generateQueryString = (obj: any): string =>
  Object.values(obj).filter(Boolean).length
    ? qs.stringify(obj, {
        encode: false,
        arrayFormat: 'comma',
        addQueryPrefix: true,
        skipNulls: true
      })
    : '';

export const keepOnlyQueryStringFromURL = (url: string): string => {
  if (!url.includes('?')) {
    return '';
  }
  const queryStringObj = readQueryString(url);

  const AUTHORIZED_FIELDS = [...AUTHORIZED_FILTER_FIELDS, 'locationIds'];

  const queryObj = typedKeys(queryStringObj)
    .filter(key => AUTHORIZED_FIELDS.includes(key as FilterTypes))
    .reduce((acc: QueryObject, key) => {
      acc[key] = queryStringObj[key];
      return acc;
    }, {});

  return generateQueryString(queryObj);
};

export const searchStateToUrl = (state: ArbitraryObject): string => {
  const { filters, page } = state;

  return filters
    ? generateQueryString({
        ...searchStateToURLObject(filters),
        page: page || 1
      })
    : '';
};

export const cleanQueryState = (query: AlgoliaSearchState) =>
  Object.entries(query).reduce((a, [k, v]) => (v ? { ...a, [k]: v } : a), {});
