import { DEFAULT_PROPERTIES, DEFAULT_STATUS } from '@/constants/constants';

import {
  AlgoliaSearchState,
  LocationState,
  OtherCriteriasProps
} from '../search/interfaces';

export const DEFAULT_OTHER: OtherCriteriasProps = {
  groundFloor: false,
  hasCellarOrAnnex: false,
  hasLift: false,
  hasParking: false,
  hasPool: false,
  hasOutdoorSpace: false,
  withoutRenovationWork: false
};

export const INITIAL_FILTERS_STATE: AlgoliaSearchState = {
  localisation: [],
  nbBedrooms: {},
  nbPieces: {},
  other: DEFAULT_OTHER,
  prix: {},
  status: DEFAULT_STATUS,
  surface: {},
  typeBien: DEFAULT_PROPERTIES
};

export type FiltersContextProps = {
  searchState: AlgoliaSearchState;
  locationState?: LocationState[];
  applyFilters(): void;
  toggleSold(checked: boolean): void;
  updateFilters(state: AlgoliaSearchState): void;
};
