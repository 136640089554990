import algoliasearch from 'algoliasearch/lite';

import { LogLevelEnum } from '@algolia/logger-common';
import { createConsoleLogger } from '@algolia/logger-console';

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '',
  process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || '',
  {
    logger: createConsoleLogger(LogLevelEnum.Error),
    timeouts: { connect: 2, read: 4, write: 4 }
  }
);

export const ALGOLIA_INDEX =
  process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME || 'annonces';
export const DEFAULT_ALGOLIA = {
  indexName: ALGOLIA_INDEX,
  searchClient
};
