import qs from 'qs';
import { ParsedUrlQuery } from 'querystring';
import { ReactElement } from 'react';

import Cave from '@/assets/icons/cave.svg';
import Elevator from '@/assets/icons/elevator.svg';
import Ground from '@/assets/icons/ground.svg';
import Parking from '@/assets/icons/parking.svg';
import Pool from '@/assets/icons/pool.svg';
import Renovation from '@/assets/icons/renovation.svg';
import Terrace from '@/assets/icons/terrace.svg';
import { PolygonIris } from '@/components/alert/buyerMap/interfaces';
import { GeoResponseAPI } from '@/components/common/locationRefinement/interfaces';
import { searchStateToURLObject } from '@/utils/algolia';
import { buildFiltersFromQuery } from '@/utils/filters';
import { ArbitraryObject } from '@/utils/test-utils';
import polyline, { decode } from '@mapbox/polyline';
import { generateLatLng, ListingStatus } from '@proprioo/hokkaido';

import { INITIAL_FILTERS_STATE } from '../filtersProvider/FiltersProvider';
import { ALGOLIA_INDEX } from './clientAlgolia';
import { AlgoliaSearchState, LocationState } from './interfaces';

type Criteria = {
  name: string;
  text: string;
  dataTest: string;
  image: ReactElement;
};

export const CRITERIAS: Record<string, Criteria> = {
  hasOutdoorSpace: {
    name: 'hasBalconySurface',
    text: 'hasBalconySurface',
    dataTest: 'criteriaBalconySurface',
    image: <Terrace />
  },
  hasParking: {
    name: 'parkingBox',
    text: 'parkingBox',
    dataTest: 'criteriaParkingBox',
    image: <Parking />
  },
  hasCellarOrAnnex: {
    name: 'caveAnnex',
    text: 'caveAnnex',
    dataTest: 'criteriaCaveAnnex',
    image: <Cave />
  },
  hasLift: {
    name: 'hasLift',
    text: 'hasLift',
    dataTest: 'criteriaElevator',

    image: <Elevator />
  },
  hasPool: {
    name: 'hasPool',
    text: 'hasPool',
    dataTest: 'criteriaPool',
    image: <Pool />
  },
  groundFloor: {
    name: 'groundFloor',
    text: 'groundFloor',
    dataTest: 'criteriaGroundFloor',
    image: <Ground />
  },
  withoutRenovationWork: {
    name: 'noRenovation',
    text: 'noRenovation',
    dataTest: 'criteriaNoRenovation',
    image: <Renovation />
  }
};

export const generateSearchState = (
  state: AlgoliaSearchState,
  hitsPerPage: number
): ArbitraryObject => {
  const { page, status, ...rest } = state;
  const defaultStatus =
    status && [ListingStatus.PUBLISHED, ListingStatus.SOLD].includes(status)
      ? status
      : ListingStatus.PUBLISHED;

  return {
    attributesToHighlight: [],
    disjunctiveFacets: [],
    disjunctiveFacetsRefinements: {},
    facets: [],
    facetsExcludes: {},
    facetsRefinements: {},
    filters: buildFiltersFromQuery({ ...rest, status: defaultStatus }),
    hierarchicalFacets: [],
    hierarchicalFacetsRefinements: {},
    highlightPostTag: '__/ais-highlight__',
    highlightPreTag: '__ais-highlight__',
    hitsPerPage,
    index: ALGOLIA_INDEX,
    numericRefinements: {},
    page: Number(page) || 1,
    tagRefinements: []
  };
};

export const getStateFromFilters = (
  searchState: ArbitraryObject
): AlgoliaSearchState =>
  searchState.filters
    ? searchStateToURLObject(searchState.filters)
    : INITIAL_FILTERS_STATE;

export const generateLocationParam = (locationIds: string[]) =>
  locationIds.length
    ? `&${qs.stringify(
        { locationIds },
        { encode: false, arrayFormat: 'comma' }
      )}`
    : '';

export const getDecodedGeom = (geom: string[][]): number[][] =>
  geom.reduce((acc: number[][], item) => {
    const polygon = [];
    const [encodedPolyline] = item;
    const decodedPolyline = decode(encodedPolyline);

    for (const array of decodedPolyline) {
      const [left, right] = array;
      polygon.push(left, right);
    }

    acc.push(polygon);
    return acc;
  }, []);

export const getLocationNameWithoutDistrict = (name: string) =>
  name.replace(/e Arrondissement/, 'ème').replace(/er Arrondissement/, 'er');

export const getLocationState = (
  locationByIds: GeoResponseAPI[]
): LocationState[] =>
  locationByIds.map(({ id, name, geom, type }) => {
    const [polygon] = geom.flat();
    const geoJson = polyline.decode(polygon);

    return {
      id,
      coordinates: generateLatLng(geoJson),
      geom: getDecodedGeom(geom),
      name: getLocationNameWithoutDistrict(name),
      type
    };
  });

export const getLocationNames = (locationState: LocationState[]) =>
  locationState.map(({ name }) => name);

export const getLocationIds = (
  locationState: LocationState[] | PolygonIris[]
) => locationState.map(({ id }) => id);

export const getLocationGeoms = (locationState: LocationState[]) =>
  locationState.map(({ geom }) => geom);

export const getLocationIdsFromQuery = (query: ParsedUrlQuery): string[] =>
  query.locationIds?.length ? `${query.locationIds}`.split(',') : [];
